body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.MuiStack-root > div {
  display: flex;
  width: max-content;
}
.css-t6tio9-RaFilterButton-root {
  width: max-content;
}
.MuiStack-root > div > button {
  width: max-content;
}
.MuiStack-root > div > a {
  width: max-content;
}
.RaSidebar-docked {
  border-right: 1px solid rgb(224, 224, 224);
}

@keyframes load {
    0% {
        background: #fff;
        border: 0px solid #fff;
    }
    50% {
        background: #fff;
        border: 2px solid #1565c0;
        opacity: 80%;
    }
    100% {
        background: #fff;
        border: 0px solid #fff;
        opacity: 90%;
    }
}